import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private toastController: ToastController,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = 'An error occurred please try again';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

          if (error.status === 401) {
            window.localStorage.removeItem('user_token');
            this.router.navigate(['login']);
            return EMPTY;
          }
        }
        if (error?.error?.message && !Array.isArray(error?.error?.message)) {
          errorMessage = error?.error?.message;
        } else {
          errorMessage = Array.isArray(error?.error?.message)
            ? error?.error?.message.join(', ')
            : error?.message ?? errorMessage;
        }

        this.presentToast(
          errorMessage,
          'danger',
          'close-circle-outline'
        ).then();

        if (environment.production) {
          return throwError(errorMessage);
        } else {
          return throwError(error);
        }

        return EMPTY;
      })
    );
  }

  async presentToast(
    message: string,
    color: string = 'success',
    icon = 'checkmark-done-circle'
  ) {
    const toast = await this.toastController.create({
      message,
      icon,
      color,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  }
}
