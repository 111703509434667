import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private tokenService: HttpXsrfTokenExtractor,
    private loaderService: LoaderService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();

    if (req.url.includes('aws')) {
      return next.handle(req.clone());
    }

    let headers = {
      credentials: 'include',
    };

    if (window.localStorage.getItem('user_token')) {
      // @ts-ignore
      headers['Authorization'] =
        'Bearer ' + window.localStorage.getItem('user_token');
    }

    const changeRequest = req.clone({
      setHeaders: headers,
      withCredentials: true,
    });

    return next
      .handle(changeRequest)
      .pipe(finalize(() => this.loaderService.hide()));
  }
}
